import React, { ReactElement } from "react";
import Reassurance from "./icons/reassurance";
import FinancialSecurity from "./icons/financialSecurity";
import Convenience from "./icons/convenience";
import FeatureList from "@components/shared/featureList";
import styles from "./style.module.scss";
const whyUsList = [
    {
        title: "Reassurance:",
        description:
            "Extended warranties provide consumers with the confidence that unforeseen repair expenses will be taken care of, offering them a sense of protection.",
        icon: <Reassurance />,
    },
    {
        title: "Financial Security:",
        description:
            "Depending on the type and level of coverage provided, extended warranties can provide significant financial security in case of a breakdown or malfunction.",
        icon: <FinancialSecurity />,
    },
    {
        title: "Convenience:",
        description:
            "Repair services are usually fast and reliable for products under an extended warranty, so no time is wasted.",
        icon: <Convenience />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="The Benefits Extended Warranties Provide Include the Following:"
            colors={{ titleColor: "#4F96F6" }}
            classes={{
                mainTitleClasses:
                    "font-bold lg:text-3xl text-xl lg:leading-[45px]",
                innerSectionClasses: "flex flex-wrap mt-[40px] gap-[34px]",
                oneBoxClasses: `  w-[360px] sm:h-[329px] ${styles["oneBoxStyle"]} py-[30px] sm:pt-[42px] sm:px-[32px] px-[15px] mx-auto lg:mx-0`,
                iconClasses: " ",
                titleClasses:
                    "lg:text-lg  text-base lg:leading-8 font-bold mt-[10px]",
                descriptionClasses:
                    "font-light text-sm lg:text-base   lg:leading-6 mt-[13px]",
            }}
        />
    );
}
