import React from "react";

function Reassurance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="none"
            viewBox="0 0 50 50"
        >
            <path
                fill="#4F96F6"
                d="M38.656 17.624h-4.609v-5.315H4.541v21.486h4.991c.168-.526.336-1.16.635-1.675h-3.95V13.985H32.37V32.12H18.905c.383.478.67 1.065.862 1.675h14.292v-2.01-12.486h4.597a4.64 4.64 0 013.112 1.198h-4.19v6.344h5.746v5.279h-.706c.024.047.06.083.084.131.323.491.622 1.006.85 1.544H45v-9.827c0-3.495-2.849-6.344-6.344-6.344zm.598 7.54v-2.992h3.711c.228.55.36 1.161.36 1.796v1.197h-4.07z"
            ></path>
            <path
                fill="#4F96F6"
                d="M14.452 30.552a4.082 4.082 0 00-4.07 4.07 4.082 4.082 0 004.07 4.07 4.082 4.082 0 004.07-4.07 4.082 4.082 0 00-4.07-4.07zm0 6.463a2.401 2.401 0 01-2.394-2.394 2.401 2.401 0 012.394-2.394 2.401 2.401 0 012.394 2.394 2.401 2.401 0 01-2.394 2.394z"
            ></path>
            <path
                fill="#4F96F6"
                d="M14.452 36.285a1.664 1.664 0 100-3.328 1.664 1.664 0 000 3.328zM38.428 30.552a4.082 4.082 0 00-4.07 4.07 4.082 4.082 0 004.07 4.07 4.082 4.082 0 004.07-4.07 4.082 4.082 0 00-4.07-4.07zm0 6.463a2.401 2.401 0 01-2.393-2.394 2.401 2.401 0 012.394-2.394 2.401 2.401 0 012.394 2.394 2.401 2.401 0 01-2.395 2.394z"
            ></path>
            <path
                fill="#4F96F6"
                d="M38.428 36.285a1.664 1.664 0 100-3.328 1.664 1.664 0 000 3.328zM19.623 28.995a6.708 6.708 0 006.704-6.703 6.708 6.708 0 00-6.704-6.703 6.708 6.708 0 00-6.703 6.703 6.708 6.708 0 006.704 6.703zm0-11.73a5.027 5.027 0 110 10.054 5.027 5.027 0 110-10.054z"
            ></path>
            <path
                fill="#4F96F6"
                d="M22.831 20.735l-1.389-.957-2.334 3.411-1.556-1.4-1.125 1.245 2.993 2.67 3.411-4.969z"
            ></path>
        </svg>
    );
}

export default Reassurance;
