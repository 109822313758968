import React, { ReactElement } from "react";
import MainImg from "./mainImg";

import SharedButton from "../sharedButton";
export default function WhatIsCarWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-28 gap-16  lg:mt-28 mt-14 lg:mb-48 mb-20 ">
            <div className="max-w-[574px]  mx-auto lg:mx-0 order-1 lg:order-2">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] ">
                    What Is a Car Warranty For?
                </h2>
                <p className="mt-5   lg:text-lg text-base lg:leading-8  font-light">
                    A car warranty is a contract between the car manufacturer or
                    dealership and the vehicle owner that provides coverage for
                    certain repairs and services. It is designed to protect the
                    owner from unexpected expenses related to mechanical
                    breakdowns and failures. A car warranty typically covers the
                    cost of repairs, parts replacements, and labor for specified
                    components and systems of the vehicle. Depending on the type
                    of warranty, it may provide coverage for a specific period
                    of time or a certain number of miles driven. Having a car
                    warranty gives owners the comfort of knowing that they can
                    rely on the warranty provider to address and cover the cost
                    of qualified repairs, reducing the financial burden
                    associated with unforeseen vehicle issues.
                </p>
                <div className="lg:mt-[50px] mt-7 text-center sm:text-left">
                    <SharedButton />
                </div>
                <span className="w-[122px] h-[8px] lg:block bg-[#F02B17] mt-9 hidden"></span>
            </div>
            <div className="w-[250px] h-[250px] md:h-[437px] md:w-[440px] md:min-w-[440px] relative mx-auto  lg:mt-16 order-2 lg:order-1">
                <MainImg />
            </div>
        </section>
    );
}
