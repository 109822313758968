import React from "react";

function Convenience() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="none"
            viewBox="0 0 50 50"
        >
            <mask
                id="mask0_0_896"
                style={{ maskType: "alpha" }}
                width="50"
                height="50"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#4F96F6" d="M0 0H50V50H0z"></path>
            </mask>
            <g fill="#4F96F6" mask="url(#mask0_0_896)">
                <path d="M37.785 47H12.141A3.145 3.145 0 019 43.858V9.701a3.317 3.317 0 013.318-3.308h6.067a.916.916 0 110 1.833h-6.068c-.818 0-1.484.662-1.484 1.475v34.157a1.31 1.31 0 001.308 1.309h25.644a1.31 1.31 0 001.308-1.309V9.701c0-.813-.666-1.475-1.484-1.475h-6.068a.916.916 0 110-1.833h6.068A3.317 3.317 0 0140.926 9.7v34.157A3.145 3.145 0 0137.786 47z"></path>
                <path d="M37.785 47H12.141A3.145 3.145 0 019 43.858V9.701a3.317 3.317 0 013.318-3.308h6.067a.916.916 0 110 1.833h-6.068c-.818 0-1.484.662-1.484 1.475v34.157a1.31 1.31 0 001.308 1.309h25.644a1.31 1.31 0 001.308-1.309V9.701c0-.813-.666-1.475-1.484-1.475h-6.068a.916.916 0 110-1.833h6.068A3.317 3.317 0 0140.926 9.7v34.157A3.145 3.145 0 0137.786 47z"></path>
                <path d="M33.883 11.613h-17.84a.917.917 0 01-.753-1.439l4.69-6.779A.916.916 0 0120.732 3h8.46c.3 0 .582.148.753.395l4.691 6.78a.915.915 0 01-.754 1.438zm-16.09-1.834h14.341l-3.422-4.946h-7.498L17.793 9.78zM36.221 29.77H23.23a.916.916 0 110-1.834H36.22a.916.916 0 110 1.834zM36.221 19.059H23.23a.916.916 0 110-1.834H36.22a.916.916 0 110 1.834zM36.221 40.73H23.23a.916.916 0 110-1.834H36.22a.916.916 0 110 1.834zM17.155 21.422a3.284 3.284 0 01-3.28-3.28 3.284 3.284 0 013.28-3.28 3.284 3.284 0 013.28 3.28 3.284 3.284 0 01-3.28 3.28zm0-4.727c-.797 0-1.446.65-1.446 1.447 0 .798.649 1.447 1.446 1.447.798 0 1.447-.649 1.447-1.447s-.65-1.447-1.447-1.447zM17.155 32.133a3.284 3.284 0 01-3.28-3.28 3.284 3.284 0 013.28-3.28 3.284 3.284 0 013.28 3.28 3.284 3.284 0 01-3.28 3.28zm0-4.726c-.797 0-1.446.649-1.446 1.446 0 .798.649 1.447 1.446 1.447.798 0 1.447-.65 1.447-1.447s-.65-1.446-1.447-1.446zM16.14 42.99a.913.913 0 01-.648-.268l-2.435-2.435a.916.916 0 111.296-1.296l1.787 1.787 3.876-3.875a.916.916 0 111.296 1.296l-4.524 4.523a.913.913 0 01-.648.269z"></path>
                <path d="M24.964 8.227a.916.916 0 01-.348-.073.766.766 0 01-.303-.202 1.099 1.099 0 01-.201-.293.89.89 0 01-.065-.349c0-.128.018-.238.065-.357.054-.11.119-.211.201-.294a.96.96 0 011.302 0 .948.948 0 01.265.651.925.925 0 01-.265.642.93.93 0 01-.303.202.916.916 0 01-.348.073z"></path>
            </g>
        </svg>
    );
}

export default Convenience;
