import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 437 434"
        >
            <g clipPath="url(#clip0_0_53)">
                <path
                    fill="#91B3FA"
                    d="M174.953 309.406S133.898 127.383 43.097 27.867c-6.104-6.688-17.594-4.155-20.232 4.49C5.198 90.18-30.036 265.62 174.953 309.406z"
                ></path>
                <path
                    fill="#fff"
                    d="M156.349 297.621c.45.434-.173 1.12-.686.748-18.818-13.721-91.287-81.073-115.796-214.93-.078-.429.581-.591.702-.173 8.632 31.039 44.185 145.496 115.78 214.355z"
                    opacity="0.24"
                ></path>
                <path
                    fill="#BDD0FB"
                    d="M176.298 223.439S160.039 84.685 98.977 3.553C94.873-1.9 85.89-.9 83.157 5.338c-18.279 41.704-59.706 169.668 93.136 218.101h.005z"
                ></path>
                <path
                    fill="#fff"
                    d="M163.075 213.224c.309.361-.225.822-.581.502-13.228-11.67-62.962-67.435-70.584-169.034-.026-.325.492-.398.55-.073 4.02 23.785 21.671 111.788 70.621 168.6l-.006.005z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M420.38 165.528H330.7v221.059h89.68V165.528z"
                ></path>
                <path
                    fill="url(#paint0_linear_0_53)"
                    d="M432.388 180.469H326.423l-19.394-77.918h102.667l26.864 71.382c1.414 3.051-.811 6.536-4.172 6.536z"
                ></path>
                <path
                    fill="url(#paint1_linear_0_53)"
                    d="M409.701 103.624l-115.618.466-3.203-9.603 111.723.601c3.916 0 7.093 2.319 7.093 5.176v3.36h.005z"
                ></path>
                <g opacity="0.89">
                    <path
                        fill="#fff"
                        d="M400.845 231.331H350.77a.586.586 0 00-.586.586v77.814c0 .324.262.586.586.586h50.075a.586.586 0 00.586-.586v-77.814a.587.587 0 00-.586-.586z"
                        opacity="0.71"
                    ></path>
                    <path
                        fill="#91B3FA"
                        d="M373.493 235.602h-19.248a.324.324 0 00-.324.324v69.796c0 .179.145.325.324.325h19.248c.18 0 .325-.146.325-.325v-69.796a.324.324 0 00-.325-.324zM397.369 235.602h-19.248a.325.325 0 00-.325.324v69.796c0 .179.146.325.325.325h19.248a.325.325 0 00.324-.325v-69.796a.324.324 0 00-.324-.324z"
                    ></path>
                </g>
                <path
                    fill="#7D97F4"
                    d="M106.295 386.587h-84.35a5.33 5.33 0 01-5.33-5.328V165.528h89.68v221.059z"
                ></path>
                <path
                    fill="url(#paint2_linear_0_53)"
                    d="M4.607 180.469h105.965l37.826-82.79-121.104 4.872-26.86 71.387c-1.413 3.051.812 6.536 4.173 6.536v-.005z"
                ></path>
                <path
                    fill="url(#paint3_linear_0_53)"
                    d="M27.294 103.624l122.7-1.937 9.606-8.001-124.669 1.402c-4.219 0-7.642 2.319-7.642 5.176v3.36h.005z"
                ></path>
                <g opacity="0.89">
                    <path
                        fill="#fff"
                        d="M35.104 310.317H85.18a.587.587 0 00.586-.586v-77.814a.587.587 0 00-.586-.586H35.104a.587.587 0 00-.586.586v77.814c0 .323.262.586.586.586z"
                        opacity="0.71"
                    ></path>
                    <path
                        fill="#91B3FA"
                        d="M62.46 306.047h19.248c.18 0 .325-.146.325-.325v-69.796a.324.324 0 00-.325-.324H62.46a.324.324 0 00-.324.324v69.796c0 .179.145.325.324.325zM38.58 306.047h19.248c.179 0 .324-.146.324-.325v-69.796a.324.324 0 00-.324-.324H38.58a.324.324 0 00-.325.324v69.796c0 .179.146.325.325.325z"
                    ></path>
                </g>
                <path
                    fill="#91B3FA"
                    d="M218.5 57.383S96.92 149.148 93.905 149.148l-1.356 236.648 250.217 1.57.329-238.218L218.5 57.383z"
                ></path>
                <path
                    fill="#BDD0FB"
                    d="M235.57 215.569h-45.285a3.72 3.72 0 01-3.722-3.721v-3.25a3.724 3.724 0 013.466-3.716l45.285-3.145a3.723 3.723 0 013.983 3.716v6.395a3.719 3.719 0 01-3.721 3.721h-.006z"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M171.158 334.683h80.959c.916 0 1.66.743 1.66 1.659v15.438h-84.278v-15.438a1.66 1.66 0 011.659-1.659z"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M167.583 349.859h90.816a1.66 1.66 0 011.659 1.659v15.962h-94.135v-15.962a1.66 1.66 0 011.66-1.659z"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M160.254 367.129h109.661a1.66 1.66 0 011.659 1.659v17.532H158.595v-17.532a1.66 1.66 0 011.659-1.659z"
                ></path>
                <path
                    fill="url(#paint4_linear_0_53)"
                    d="M188.427 221.446h49.007c1.026 0 1.863.837 1.863 1.863V336.08h-52.734V223.309c0-1.026.838-1.863 1.864-1.863z"
                ></path>
                <path
                    fill="#BDD0FB"
                    d="M205.607 296.642h-7.957a4.814 4.814 0 01-4.81-4.809v-58.822a4.814 4.814 0 014.81-4.809h7.957a4.814 4.814 0 014.811 4.809v58.822a4.814 4.814 0 01-4.811 4.809zm-7.957-67.865a4.24 4.24 0 00-4.234 4.234v58.822a4.24 4.24 0 004.234 4.234h7.957a4.24 4.24 0 004.235-4.234v-58.822a4.24 4.24 0 00-4.235-4.234h-7.957zM227.818 296.642h-7.957a4.813 4.813 0 01-4.81-4.809v-58.822a4.813 4.813 0 014.81-4.809h7.957a4.814 4.814 0 014.811 4.809v58.822a4.814 4.814 0 01-4.811 4.809zm-7.957-67.865a4.24 4.24 0 00-4.234 4.234v58.822a4.24 4.24 0 004.234 4.234h7.957a4.24 4.24 0 004.235-4.234v-58.822a4.24 4.24 0 00-4.235-4.234h-7.957zM205.607 330.601h-7.957c-2.653 0-4.81-4.019-4.81-8.959v-12.597c0-4.94 2.157-8.959 4.81-8.959h7.957c2.654 0 4.811 4.019 4.811 8.959v12.597c0 4.94-2.157 8.959-4.811 8.959zm-7.957-29.442c-2.334 0-4.234 3.537-4.234 7.881v12.597c0 4.348 1.9 7.886 4.234 7.886h7.957c2.335 0 4.235-3.538 4.235-7.886V309.04c0-4.349-1.9-7.881-4.235-7.881h-7.957zM227.818 330.601h-7.957c-2.654 0-4.81-4.019-4.81-8.959v-12.597c0-4.94 2.156-8.959 4.81-8.959h7.957c2.654 0 4.811 4.019 4.811 8.959v12.597c0 4.94-2.157 8.959-4.811 8.959zm-7.957-29.442c-2.334 0-4.234 3.537-4.234 7.881v12.597c0 4.348 1.9 7.886 4.234 7.886h7.957c2.335 0 4.235-3.538 4.235-7.886V309.04c0-4.349-1.9-7.881-4.235-7.881h-7.957z"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M281.17 195.002h-.503c-13.024 0-24.425 9.216-24.425 22.236v23.45h24.928v-45.686zM256.242 247.612v68.985c0 2.324 2.727 5.924 5.052 5.924h19.876v-74.909h-24.928zM287.98 195.002h-1.267v45.686h24.927v-23.45c0-13.02-10.636-22.236-23.66-22.236zM286.713 322.521h20.64c2.324 0 4.287-3.6 4.287-5.924v-68.985h-24.927v74.909z"
                ></path>
                <path
                    fill="#BDD0FB"
                    d="M313.792 326.828h-60.005c-3.439 0-6.24-2.8-6.24-6.238V216.725c0-16.427 13.364-29.787 29.796-29.787h12.893c16.432 0 29.796 13.36 29.796 29.787V320.59c0 3.438-2.801 6.238-6.24 6.238zm-36.449-139.315c-16.112 0-29.22 13.104-29.22 29.212V320.59a5.67 5.67 0 005.664 5.662h60.005a5.67 5.67 0 005.664-5.662V216.725c0-16.108-13.108-29.212-29.22-29.212h-12.893z"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M143.875 195.002h-.503c-13.024 0-24.425 9.216-24.425 22.236v23.45h24.928v-45.686zM118.947 247.612v68.985c0 2.324 2.728 5.924 5.052 5.924h19.876v-74.909h-24.928zM150.68 195.002h-1.267v45.686h24.928v-23.45c0-13.02-10.637-22.236-23.661-22.236zM149.413 322.521h20.64c2.325 0 4.288-3.6 4.288-5.924v-68.985h-24.928v74.909z"
                ></path>
                <path
                    fill="#BDD0FB"
                    d="M176.523 326.828h-60.004c-3.44 0-6.24-2.8-6.24-6.238V216.725c0-16.427 13.364-29.787 29.795-29.787h12.893c16.432 0 29.796 13.36 29.796 29.787V320.59c0 3.438-2.8 6.238-6.24 6.238zm-36.449-139.315c-16.112 0-29.219 13.104-29.219 29.212V320.59a5.67 5.67 0 005.664 5.662h60.004a5.67 5.67 0 005.664-5.662V216.725c0-16.108-13.107-29.212-29.22-29.212h-12.893z"
                ></path>
                <path
                    fill="url(#paint5_linear_0_53)"
                    d="M293.67 59.387h-20.614v33.55h20.614v-33.55z"
                ></path>
                <path
                    fill="url(#paint6_linear_0_53)"
                    d="M270.339 54.52h25.268a4.86 4.86 0 014.858 4.856v5.448h-33.314v-7.112a3.195 3.195 0 013.194-3.192h-.006z"
                ></path>
                <path
                    fill="url(#paint7_linear_0_53)"
                    d="M86.267 158.767L218.5 57.383l125.522 97.333c4.565 3.538 11.255 1.063 12.411-4.595l1.759-8.608L218.5 35.131 83.284 138.378a6.733 6.733 0 00-2.141 7.907l5.124 12.477v.005z"
                ></path>
                <path
                    fill="url(#paint8_linear_0_53)"
                    d="M386.161 275.113c2.68 2.857 6.627 5.332 9.401 6.871-4.146 5.123-9.757 10.257-17.185 15.276l-2.125 4.767-6.811-8.158s12.223-6.081 16.72-18.756z"
                ></path>
                <path
                    fill="url(#paint9_linear_0_53)"
                    d="M349.712 429.897c0-.146.005-.293.047-.445 2.204-8.745 13.155-14.187 13.155-14.187l4.339.34c2.497 7.709 2.173 12.434 1.775 14.538-.011.058-.032.115-.047.173-3.581.319-10.946.528-19.269-.419z"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M368.975 430.316c-.193.701-.712 1.251-1.376 1.392-5.094 1.073-13.186.209-16.584-.236-.748-.099-1.298-.79-1.308-1.575 8.323.947 15.688.738 19.268.419z"
                ></path>
                <path
                    fill="url(#paint10_linear_0_53)"
                    d="M368.991 409.142l-1.837 8.844c-.137.649-.634 1.136-1.236 1.188-.738.068-1.79.11-2.847-.047-.948-.141-1.607-1.104-1.44-2.135l1.277-7.855h6.083v.005z"
                ></path>
                <path
                    fill="#7D97F4"
                    d="M330.919 430.227c4.79-.136 7.271-.696 8.554-1.225 0 .477 0 .963-.016 1.466-.016.534-.414.963-.9.963l-26.31-.168c-.837-.005-1.329-.978-.968-1.748 3.449.283 12.233.921 19.645.717l-.005-.005z"
                ></path>
                <path
                    fill="url(#paint11_linear_0_53)"
                    d="M311.274 429.51c.031-.068.057-.136.104-.199 2.325-3.245 8.24-9.708 19.934-12.513l5.612-.178s2.497 4.339 2.544 12.382c-1.283.524-3.759 1.089-8.554 1.225-7.407.209-16.196-.429-19.645-.717h.005z"
                ></path>
                <path
                    fill="url(#paint12_linear_0_53)"
                    d="M337.965 408.122c-.063.418-.832 6.771-1.13 9.236-.074.586-.498 1.036-1.032 1.104-.958.116-2.502.22-3.852-.057-.728-.152-1.257-.848-1.257-1.664v-9.975l7.271 1.361v-.005z"
                ></path>
                <path
                    fill="url(#paint13_linear_0_53)"
                    d="M342.467 292.168s-13.531 51.406-13.285 116.1c0 1.177.832 2.198 1.989 2.439 1.701.356 4.203.785 6.271.727a2.32 2.32 0 002.214-1.941c1.895-11.121 12.783-73.784 18.986-83.958 0 0 4.083 55.808 2.775 84.764a1.374 1.374 0 001.209 1.428c1.089.126 2.821.22 5.036-.016a1.838 1.838 0 001.638-1.559c1.351-9.415 9.129-65.447 8.799-105.811-.02-2.277-6.019-12.173-6.019-12.173h-29.613z"
                ></path>
                <path
                    fill="url(#paint14_linear_0_53)"
                    d="M323.649 229.95a56.155 56.155 0 005.726-8.74c2.246-4.244.184 2.748.796-2.046 16.829-12.141 42.176-5.997 51.98-4.762a13.08 13.08 0 018.271 4.438c8.402 9.718 26.833 36.329 5.135 63.139-2.774-1.539-6.721-4.014-9.401-6.871 2.402-6.767 2.596-15.412-2.434-26.01a24.013 24.013 0 01-.644 2.491c-6.936 21.959-7.303 40.234-7.287 44.447 0 .57-.34 1.088-.874 1.297-9.124 3.658-26.692 1.481-32.591.602a1.979 1.979 0 01-1.659-2.25c2.894-20.959-11.962-55.441-15.882-64.081a5.622 5.622 0 00-1.147-1.659l.011.005z"
                ></path>
                <path
                    fill="url(#paint15_linear_0_53)"
                    d="M366.379 196.75l1.759 17.966s-10.108 3.046-17.463-2.141l5.166-16.327 10.533.502h.005z"
                ></path>
                <path
                    fill="url(#paint16_linear_0_53)"
                    d="M354.648 176.278c-.241.23-10.265 19.907-10.265 19.907l11.359 5.484 12.275-17.243s-9.296-11.984-13.369-8.154v.006z"
                ></path>
                <path
                    fill="url(#paint17_linear_0_53)"
                    d="M362.987 184.907c.204.523.151 1.11-.136 1.591l-5.67 9.446a1.815 1.815 0 01-.308.387c-.676.628-2.984 2.455-4.769-.031-1.523-2.12-2.947-4.045-4.476-4.841-.895-.466-1.989-.094-2.444.806-.932 1.853-2.743 5.621-3.879 9.195a1.808 1.808 0 001.052 2.214c2.298.931 6.847 2.616 10.883 3.171a1.8 1.8 0 001.711-.728c1.738-2.375 6.628-9.022 8.297-10.686 1.55-1.544 4.413-.963 5.706-.591a1.83 1.83 0 001.45-.189c1.309-.79 3.591-2.752 1.503-6.075-.519-.827-.257-1.926.56-2.465 1.46-.963 3.046-2.763 1.539-5.689-1.529-2.957-4.261-3.224-6.12-2.993-.822.099-1.659-.33-1.973-1.099-.141-.34-.419-.633-.937-.738-.974-.199-1.618-1.089-1.424-2.067.33-1.675.079-3.847-2.591-5.317-4.905-2.695-9.197 1.695-10.286 5.4-1.094 3.706 4.386 6.327 7.836 6.762 2.534.319 3.921 3.082 4.481 4.527l-.005.01z"
                ></path>
                <path
                    fill="url(#paint18_linear_0_53)"
                    d="M361.123 198.896s2.178-6.375 4.968-6.144c2.79.235 4.988 3.014 1.528 6.138-4.303 3.889-6.496.006-6.496.006z"
                ></path>
                <path
                    fill="url(#paint19_linear_0_53)"
                    d="M384.648 247.455c3.68-10.063 2.345-22.215 2.329-22.335l-1.015.115c.015.12 1.319 12.036-2.277 21.87l.958.35h.005z"
                ></path>
                <path
                    fill="url(#paint20_linear_0_53)"
                    d="M359.14 324.227a.508.508 0 00.492-.382c1.12-4.328 9.521-9.08 9.61-9.127a.508.508 0 00.194-.696.506.506 0 00-.696-.193c-.361.204-8.868 5.013-10.098 9.76a.51.51 0 00.498.638z"
                ></path>
                <path
                    fill="url(#paint21_linear_0_53)"
                    d="M307.839 281.675c14.233-12.957 12.747-30.159 12.93-41.923.241-15.538 10.616-21.451 10.616-21.451l9.276 17.646c1.947 26.643-12.762 49.156-25.299 55.148-3.843-2.878-6.151-6.405-7.523-9.42z"
                ></path>
                <path
                    fill="url(#paint22_linear_0_53)"
                    d="M282.143 295.256l6.621-2.753c8.355-2.962 14.521-6.678 19.076-10.828 1.371 3.015 3.68 6.542 7.522 9.42-7.983 3.815-14.668 4.841-14.668 4.841-15.641 5.709-18.551-.68-18.551-.68z"
                ></path>
                <path
                    fill="url(#paint23_linear_0_53)"
                    d="M277.306 315.289a4.646 4.646 0 01-.592-3.988l4.089-13.188s-1.152-1.188-3.848.356c-2.696 1.544-4.789-1.031-4.789-1.031s3.905-1.931 9.788-4.621c3.366-1.539 6.784-1.157 9.098-.544l1.283 5.296c-3.146 7.619-7.837 14.103-13.652 19.755l-1.377-2.035z"
                ></path>
                <path
                    fill="url(#paint24_linear_0_53)"
                    d="M312.74 301.086s4.177 1.444 5.014 7.211c.838 5.767-4.177 10.095-8.359 9.373-4.178-.722-8.659-11.534-.985-16.579s4.33 0 4.33 0v-.005z"
                ></path>
                <path
                    fill="url(#paint25_linear_0_53)"
                    d="M277.16 186.582s-5.963 4.589-14.239 8.017c-4.617 1.91-13.594 4.736-14.782 12.989-.613 4.26-4.267 7.672-9.187 8.321-12.255 1.617-31.136 7.107-34.156 25.455-2.309 14.004 5.972 19.316 14.657 21.152 6.381 1.351 10.621 6.704 9.328 12.257-1.236 5.327.607 11.487 11.312 16.359 23.985 10.917 47.939-25.863 47.939-25.863l4.91-64.212-15.782-14.475z"
                ></path>
                <path
                    fill="#fff"
                    d="M222.437 254.274c-.362 0-.775-.152-1.11-.335-5.596-3.145-8.58-9.634-7.255-15.789.79-3.684 2.91-6.86 5.962-8.943 2.162-1.476 4.732-2.381 7.182-3.182a154.233 154.233 0 0112.657-3.527c.351-.084.702-.162 1.047-.241 2.497-.576 5.083-1.167 7.418-2.177 2.999-1.293 5.182-3.177 6.156-5.301.602-1.319.774-2.779.942-4.192.204-1.727.419-3.517 1.418-5.045a.068.068 0 01.089-.021.067.067 0 01.021.089c-.984 1.502-1.198 3.276-1.397 4.987-.168 1.424-.346 2.894-.953 4.229-.984 2.156-3.193 4.061-6.219 5.369-2.35 1.015-4.936 1.607-7.443 2.182-.351.079-.702.163-1.047.241a152.044 152.044 0 00-12.647 3.527c-2.44.796-4.999 1.696-7.151 3.161-3.026 2.067-5.119 5.213-5.905 8.865-1.313 6.092 1.644 12.529 7.193 15.643.424.24 1.235.528 1.591.099.021-.026.063-.031.094-.01.027.021.032.062.011.094-.157.193-.393.267-.649.267l-.005.01z"
                ></path>
                <path
                    fill="#fff"
                    d="M220.997 248.476s-.037-.011-.053-.027c-2.156-2.784-3.282-5.709-3.25-8.451.036-3.214 1.764-6.202 4.512-7.798.031-.021.068-.005.089.021.015.031.005.073-.026.089-2.707 1.57-4.408 4.516-4.445 7.688-.031 2.716 1.084 5.61 3.225 8.373.021.026.016.068-.011.094a.056.056 0 01-.041.016v-.005z"
                ></path>
                <path
                    fill="url(#paint26_linear_0_53)"
                    d="M302.652 241.249s13.375 33.163 17.552 62.72l-4.177 3.606s-13.375-37.486-23.404-48.303l10.029-18.023z"
                ></path>
                <path
                    fill="url(#paint27_linear_0_53)"
                    d="M214.323 423.774l-.906 6.427c-.173 1.219-1.288 2.182-2.701 2.323-2.387.236-6.224.477-9.977.137-1.649-.152-2.717-1.591-2.215-2.952.916-2.481 2.586-6.207 5-8.399h8.27c1.529 0 2.712 1.151 2.529 2.464z"
                ></path>
                <path
                    fill="url(#paint28_linear_0_53)"
                    d="M279.086 348.468l1.398 7.08s-4.675 40.83-5.507 57.163c-.131 2.559.638 5.092 2.193 7.274l8.124 11.383-20.651-10.514-.413-2.172 3.381-4.008-4.428-45.739c-.262-2.685.382-5.38 1.853-7.761l4.271-6.924 9.774-5.777.005-.005z"
                ></path>
                <path
                    fill="url(#paint29_linear_0_53)"
                    d="M286.655 433.016h-6.59c-1.235 0-2.398-.486-3.151-1.329-2.058-2.303-6.313-6.793-8.35-6.913-2.737-.157-2.387 8.242-2.387 8.242h-1.533l-1.948-10.44c-.492-2.627 1.026-5.228 3.759-6.458 0 0 .628 3.993 4.025 5.762 3.398 1.769 13.82 8.096 13.82 8.096s1.277-.157 2.68.063c2.078.324 1.79 2.977-.325 2.977z"
                ></path>
                <path
                    fill="url(#paint30_linear_0_53)"
                    d="M250.798 351.833s-27.377 44.791-31.733 55.022c-4.35 10.226-5.878 18.761-5.936 19.567-.089 1.262-1.91 2.476-6.93 2.476-2.592 0-3.858-.895-4.481-1.764-.529-.738-.555-1.664-.11-2.444 1.586-2.763 6.611-11.597 8.47-15.606 2.224-4.783 7.003-26.464 9.626-34 2.623-7.536 5.585-15.716 17.002-23.246 0 0 7.837-6.62 14.087 0l.005-.005z"
                ></path>
                <path
                    fill="url(#paint31_linear_0_53)"
                    d="M268.093 278.436c7.062-25.81-10.83-40.411-10.83-40.411 11.291-9.347 31.858-13.601 31.858-13.601s19.625 12.376 17.3 22.063c-1.366 5.678-13.793 34.006-13.793 34.006s-11.495 4.694-24.53-2.062l-.005.005z"
                ></path>
                <path
                    fill="url(#paint32_linear_0_53)"
                    d="M252.436 291.216c2.874-7.468 15.657-12.78 15.657-12.78 13.034 6.751 24.53 2.062 24.53 2.062s2.575 8.499.801 16.396c-1.78 7.897-12.941 58.654-12.941 58.654-17.588 3.072-44.641-3.093-44.641-3.093 11.202-17.154 13.72-53.777 16.594-61.245v.006z"
                ></path>
                <path
                    fill="url(#paint33_linear_0_53)"
                    d="M276.291 207.368l-5.743 22.247c-.549 1.957 1.524 1.638 3.816 1.266 3.764-.612 8.611-1.873 10.229-4.396.167-.256.246-.549.293-.837l2.466-15.428c.193-1.224-.571-2.412-1.88-2.914l-4.758-1.822c-1.832-.701-3.952.199-4.423 1.884z"
                ></path>
                <path
                    fill="url(#paint34_linear_0_53)"
                    d="M294.376 192.746s4.366 5.574 3.45 14.989c-.911 9.414-6.083 10.874-10.077 9.074-3.994-1.8-9.459-5.223-12.16-13.313-2.701-8.086 12.951-17.757 18.787-10.75z"
                ></path>
                <path
                    fill="url(#paint35_linear_0_53)"
                    d="M282.096 202.13s2.722-12.932 13.641-6.458l1.518 3.051s7.905-3.276 3.256-9.969c-4.648-6.694-13.835-9.096-21.708-3.397-7.873 5.699-11.757 10.362-9.579 16.71 2.183 6.348 6.847 3.501 6.847 3.501s-.236-4.841 2.01-5.987c2.246-1.146 3.135 2.428 3.135 2.428l.88.121z"
                ></path>
                <path
                    fill="url(#paint36_linear_0_53)"
                    d="M280.395 204.317s1.444-6.636-2.948-6.316c-4.397.319-.978 6.342.487 8.514l2.466-2.198h-.005z"
                ></path>
                <path
                    fill="url(#paint37_linear_0_53)"
                    fillRule="evenodd"
                    d="M299.48 287.118s-1.037-5.506 3.209-6.688c0 0-1.922-6.348 4.088-6.327 0 0 10.276-12.769 18.562.319 0 0 18.546 1.125 11.213 15.166 0 0 4.863 4.349-2.215 7.855 0 0-5.716 17.186-18.604 6.107l-16.248-16.427-.005-.005z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint38_linear_0_53)"
                    d="M358.04 274.731c-3.848-3.951-6.632 1.355-6.632 1.355-3.099 1.042 0 7.698 0 7.704-1.895 3.673-11.789 22.555-17.149 27.213-5.947 5.165 3.617 6.515 3.617 6.515s10.689-4.998 16.683-32.211c.052-.021.099-.036.141-.073.817-.623 7.193-6.552 3.34-10.503z"
                ></path>
                <path
                    fill="url(#paint39_linear_0_53)"
                    d="M297.993 314.153c-3.894-5.066-9.192-24.193-11.055-31.243.848-1.235 3.742-5.819 1.303-7.232 0 0-1.675-5.751-6.234-2.643-4.204 2.868-.215 9.106.958 10.66-.069 15.616 7.333 34.66 7.333 34.66s12.49 2.036 7.69-4.207l.005.005z"
                ></path>
                <path
                    fill="url(#paint40_linear_0_53)"
                    fillRule="evenodd"
                    d="M315.273 427.103s.864 3.202.801 5.568a.758.758 0 01-.654.732c-2.162.299-9.543 1.183-13.595 0a.748.748 0 01-.539-.737c.037-1.272.932-4.26 8.339-5.019l5.648-.539v-.005z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint41_linear_0_53)"
                    fillRule="evenodd"
                    d="M302.531 378.135c-.91.596 5.963 48.858 7.627 49.208 0 0 1.906 1.769 4.848-.246-1.696-15.731-2.733-31.687-1.817-47.989l-10.658-.973z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint42_linear_0_53)"
                    fillRule="evenodd"
                    d="M331.736 427.103s.864 3.202.801 5.568a.758.758 0 01-.655.732c-2.161.299-9.542 1.183-13.594 0a.745.745 0 01-.539-.737c.037-1.272.932-4.26 8.339-5.019l5.648-.539v-.005z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint43_linear_0_53)"
                    fillRule="evenodd"
                    d="M318.995 378.135c-.911.596 5.962 48.858 7.627 49.208 0 0 1.905 1.769 4.847-.246-1.696-15.731-2.732-31.687-1.816-47.989l-10.658-.973z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint44_linear_0_53)"
                    fillRule="evenodd"
                    d="M326.643 348.839s-22.42 1.026-27.76 2.899c0 0 .66 18.223 2.848 29.422.084.434.414.78.843.879 1.439.335 5.062.811 10.799-.796a1.12 1.12 0 00.811-1.156l-.591-7.259a.885.885 0 01.822-.942l.602-.089a.887.887 0 01.947.874l1.136 7.364c0 .476.309.9.759 1.051 1.686.571 6.072 1.639 11.856-.125a1.11 1.11 0 00.791-1.057c.057-8.933-1.544-19.625-3.863-31.06v-.005z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint45_linear_0_53)"
                    fillRule="evenodd"
                    d="M318.236 350.409s2.832 7.876 9.673 8.426l-1.512-8.986s-5.298-.042-8.161.56z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint46_linear_0_53)"
                    fillRule="evenodd"
                    d="M337.154 307.386s-10.134 6.511-22.441 5.788c-12.307-.722-17.541-5.5-20.436-9.121-.047.021-3.46.926-7.622 5.149a2.068 2.068 0 00-.345 2.449c2.732 4.988 6.224 8.709 7.799 11.859a6.615 6.615 0 011.712 4.616l-.623 23.712c-.016.659.44 1.23 1.084 1.35 4.496.853 21.566 3.825 32.182 1.952v-25.575a5.176 5.176 0 012.67-4.532c2.23-1.23 5.57-3.653 8.909-8.248l-2.894-9.409.005.01z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint47_linear_0_53)"
                    fillRule="evenodd"
                    d="M319.576 301.912l1.109 12.21s-6.443 3.768-11.992.471l.984-10.666 9.893-2.02.006.005z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint48_linear_0_53)"
                    fillRule="evenodd"
                    d="M307.803 279.347s-19.473 14.559-8.475 25.072c10.38 9.922 20.106-.89 20.106-.89s7.439-3.689 7.161-8.666c-.277-4.982-14.856-16.783-18.792-15.511v-.005z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint49_linear_0_53)"
                    fillRule="evenodd"
                    d="M322.083 300.368c-.712-.34-1.351-.936-1.822-1.816-.701-1.308-.78-2.904-.382-4.333.105-.366.231-.785.032-1.109-.241-.393-.786-.408-1.246-.455a4.175 4.175 0 01-3.288-2.35c-.591-1.277-.465-2.863.325-4.025-1.879-.486-2.083-1.962-3.418-2.883-1.225-.848-2.984-.126-4.329-.68-.99-.409-3.052-1.921-2.769-3.245.125-.591 1.837-1.853 2.392-2.245 2.481-1.753 5.193-1.23 7.386.754 6.334 5.719 12.008 11.262 13.217 14.161.848 2.03.791 4.647-.549 6.452-1.387 1.874-3.727 2.654-5.554 1.78l.005-.006z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint50_linear_0_53)"
                    fillRule="evenodd"
                    d="M317.199 304.649l4.361-5.264s4.952-.435 3.219 2.653c-1.738 3.093-6.208 3.548-7.58 2.611z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint51_linear_0_53)"
                    d="M261.979 238.543c-2.088-.979-4.695-.67-6.517.649-3.617 2.632-11.925 10.508-12.793 27.72-1.115 22.074 37.83 16.313 37.83 16.313l2.225-4.799s-27.817-.958-27.817-10.556c0-6.761 8.077-14.616 11.668-17.772 1.377-1.209 1.979-2.957 1.476-4.595-.858-2.784-3.277-5.657-6.072-6.96z"
                ></path>
                <path
                    fill="url(#paint52_linear_0_53)"
                    d="M277.62 278.436s3.989-1.92 7.329-2.883c3.34-.958 4.449 0 4.449 0s-3.339.957-4.449 1.92c-1.11.963 5.329.968 7.669.963 2.345 0 2.758 4.715.324 6.196-2.434 1.481-9.108 4.36-15.782-1.402l.46-4.794z"
                ></path>
                <path
                    fill="url(#paint53_linear_0_53)"
                    d="M127.71 389.559s-34.329 17.563-50.305-1.366c-12.84-15.213 9.376-4.118 30.602-1.005 1.665.246 2.131-2.208.492-2.59-16.107-3.758-40.14-10.932-41.186-20.462-1.576-14.386 2.894-8.227 20.912.57s23.363 5.542 12.255-1.931c-11.108-7.473-32.9-8.446-35.188-16.055-2.287-7.615-.33-20.269-.225-25.994.105-5.72 28.01-3.831 32.288 5.343 4.271 9.174 4.192 26.025 11.212 31.761 7.02 5.73 3.308-10.603.529-19.358-2.785-8.761-3.005-14.569 7.04-8.938 10.041 5.631 10.763 20.749 8.224 30.871-2.539 10.121-.837 16.966.806 12.104 1.644-4.862 5.46-20.237 3.727-25.946-1.732-5.71 20.525 8.865-1.167 43.001l-.016-.005z"
                ></path>
                <path
                    fill="#fff"
                    d="M125.643 389.067a.346.346 0 01-.304-.178c-13.929-26.051-43.83-52.448-44.128-52.709a.341.341 0 11.45-.513c.304.262 30.288 26.742 44.28 52.903a.34.34 0 01-.141.461.357.357 0 01-.157.041v-.005z"
                    opacity="0.53"
                ></path>
                <path
                    fill="url(#paint54_linear_0_53)"
                    d="M147.11 399.204s-42.16-2.156-46.929-28.851c-3.832-21.456 11.145 1.23 29.555 15.81 1.445 1.146 3.23-.927 1.884-2.183-13.207-12.387-32.052-32.352-27.822-41.955 6.392-14.501 7.25-6.212 19.51 11.995 12.259 18.207 19.101 18.05 12.673 4.883-6.434-13.167-26.556-26.025-24.551-34.493 2.005-8.467 10.799-19.389 14.034-24.758 3.235-5.364 28.65 11.712 27.671 22.754-.979 11.037-10.292 26.967-6.779 36.24 3.512 9.279 8.946-8.237 11.108-18.06 2.162-9.823 5.14-15.454 11.574-4.616 6.433 10.839-1.173 25.56-9.13 33.766-7.956 8.2-10.092 15.621-5.873 11.91 4.225-3.71 16.264-16.191 17.751-22.55 1.487-6.358 14.599 19.646-24.676 40.119v-.011z"
                ></path>
                <path
                    fill="#fff"
                    d="M145.414 397.603a.376.376 0 01-.189-.335c1.073-32.321-12.793-73.716-12.935-74.13a.367.367 0 01.236-.471.367.367 0 01.471.236c.141.413 14.05 41.939 12.977 74.396a.375.375 0 01-.56.314v-.01z"
                    opacity="0.53"
                ></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_0_53"
                    x1="394.704"
                    x2="454.349"
                    y1="94.387"
                    y2="-24.939"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_0_53"
                    x1="346.058"
                    x2="352.583"
                    y1="-6.432"
                    y2="153.497"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_0_53"
                    x1="152.941"
                    x2="212.591"
                    y1="-26.459"
                    y2="-145.785"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_0_53"
                    x1="87.738"
                    x2="94.665"
                    y1="33.378"
                    y2="112.583"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_0_53"
                    x1="45.516"
                    x2="240.637"
                    y1="431.901"
                    y2="253.924"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_0_53"
                    x1="324.779"
                    x2="376.58"
                    y1="-8.53"
                    y2="-114.51"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_0_53"
                    x1="357.004"
                    x2="324.043"
                    y1="-48.989"
                    y2="0.468"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_0_53"
                    x1="-114.288"
                    x2="243.813"
                    y1="261.349"
                    y2="112.531"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear_0_53"
                    x1="395.562"
                    x2="369.441"
                    y1="288.568"
                    y2="288.568"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint9_linear_0_53"
                    x1="360.029"
                    x2="359.375"
                    y1="370.426"
                    y2="442.473"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint10_linear_0_53"
                    x1="363.851"
                    x2="367.591"
                    y1="400.627"
                    y2="433.273"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint11_linear_0_53"
                    x1="325.91"
                    x2="325.256"
                    y1="370.118"
                    y2="442.164"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint12_linear_0_53"
                    x1="333.374"
                    x2="337.115"
                    y1="404.123"
                    y2="436.763"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint13_linear_0_53"
                    x1="288.917"
                    x2="408.541"
                    y1="254.844"
                    y2="440.513"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint14_linear_0_53"
                    x1="388.37"
                    x2="363.254"
                    y1="207.436"
                    y2="250.355"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF928E"></stop>
                    <stop offset="1" stopColor="#FE7062"></stop>
                </linearGradient>
                <linearGradient
                    id="paint15_linear_0_53"
                    x1="355.585"
                    x2="369.863"
                    y1="191.946"
                    y2="253.663"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint16_linear_0_53"
                    x1="358.108"
                    x2="351.072"
                    y1="179.99"
                    y2="221.416"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint17_linear_0_53"
                    x1="365.514"
                    x2="344.767"
                    y1="199.598"
                    y2="177.552"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint18_linear_0_53"
                    x1="364.218"
                    x2="365.47"
                    y1="225.203"
                    y2="196.116"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint19_linear_0_53"
                    x1="367.614"
                    x2="397.178"
                    y1="203.574"
                    y2="257.897"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E1473D"></stop>
                    <stop offset="1" stopColor="#E9605A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint20_linear_0_53"
                    x1="356.03"
                    x2="366.061"
                    y1="304.691"
                    y2="322.544"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint21_linear_0_53"
                    x1="362.065"
                    x2="336.949"
                    y1="192.045"
                    y2="234.964"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF928E"></stop>
                    <stop offset="1" stopColor="#FE7062"></stop>
                </linearGradient>
                <linearGradient
                    id="paint22_linear_0_53"
                    x1="243.718"
                    x2="385.653"
                    y1="288.914"
                    y2="291.709"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint23_linear_0_53"
                    x1="243.427"
                    x2="385.361"
                    y1="303.756"
                    y2="306.552"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint24_linear_0_53"
                    x1="316.65"
                    x2="301.477"
                    y1="254.269"
                    y2="402.889"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint25_linear_0_53"
                    x1="301.747"
                    x2="217.792"
                    y1="169.553"
                    y2="293.419"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint26_linear_0_53"
                    x1="325.826"
                    x2="274.496"
                    y1="91.577"
                    y2="594.303"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint27_linear_0_53"
                    x1="214.344"
                    x2="198.404"
                    y1="427.077"
                    y2="427.077"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint28_linear_0_53"
                    x1="280.436"
                    x2="260.639"
                    y1="481.487"
                    y2="250.045"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint29_linear_0_53"
                    x1="288.404"
                    x2="262.613"
                    y1="424.565"
                    y2="424.565"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint30_linear_0_53"
                    x1="234.209"
                    x2="214.532"
                    y1="484.213"
                    y2="254.158"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint31_linear_0_53"
                    x1="336.704"
                    x2="317.863"
                    y1="207.803"
                    y2="223.507"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF928E"></stop>
                    <stop offset="1" stopColor="#FE7062"></stop>
                </linearGradient>
                <linearGradient
                    id="paint32_linear_0_53"
                    x1="310.928"
                    x2="223.522"
                    y1="294.691"
                    y2="347.326"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint33_linear_0_53"
                    x1="283.462"
                    x2="279.763"
                    y1="253.149"
                    y2="226.077"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint34_linear_0_53"
                    x1="285.314"
                    x2="289.458"
                    y1="225.948"
                    y2="158.749"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint35_linear_0_53"
                    x1="306.918"
                    x2="185.728"
                    y1="179.319"
                    y2="261.746"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint36_linear_0_53"
                    x1="276.456"
                    x2="280.591"
                    y1="226.627"
                    y2="159.527"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint37_linear_0_53"
                    x1="308.614"
                    x2="343.029"
                    y1="291.49"
                    y2="280.939"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint38_linear_0_53"
                    x1="351.931"
                    x2="330.662"
                    y1="280.063"
                    y2="335.828"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4421B"></stop>
                    <stop offset="1" stopColor="#5C0E16"></stop>
                </linearGradient>
                <linearGradient
                    id="paint39_linear_0_53"
                    x1="296.227"
                    x2="272.01"
                    y1="285.71"
                    y2="323.999"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4421B"></stop>
                    <stop offset="1" stopColor="#5C0E16"></stop>
                </linearGradient>
                <linearGradient
                    id="paint40_linear_0_53"
                    x1="316.074"
                    x2="301.281"
                    y1="430.571"
                    y2="430.501"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint41_linear_0_53"
                    x1="312.036"
                    x2="304.569"
                    y1="418.025"
                    y2="350.087"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4421B"></stop>
                    <stop offset="1" stopColor="#5C0E16"></stop>
                </linearGradient>
                <linearGradient
                    id="paint42_linear_0_53"
                    x1="332.537"
                    x2="317.75"
                    y1="430.575"
                    y2="430.506"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint43_linear_0_53"
                    x1="328.501"
                    x2="321.035"
                    y1="418.027"
                    y2="350.09"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4421B"></stop>
                    <stop offset="1" stopColor="#5C0E16"></stop>
                </linearGradient>
                <linearGradient
                    id="paint44_linear_0_53"
                    x1="365.84"
                    x2="294.1"
                    y1="286.019"
                    y2="398.227"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint45_linear_0_53"
                    x1="328.694"
                    x2="321.185"
                    y1="345.693"
                    y2="357.562"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint46_linear_0_53"
                    x1="316.576"
                    x2="326.805"
                    y1="335.646"
                    y2="399.126"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF928E"></stop>
                    <stop offset="1" stopColor="#FE7062"></stop>
                </linearGradient>
                <linearGradient
                    id="paint47_linear_0_53"
                    x1="320.644"
                    x2="309.75"
                    y1="332.658"
                    y2="291.779"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4421B"></stop>
                    <stop offset="1" stopColor="#5C0E16"></stop>
                </linearGradient>
                <linearGradient
                    id="paint48_linear_0_53"
                    x1="1168.85"
                    x2="1194.56"
                    y1="615.416"
                    y2="625.019"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4421B"></stop>
                    <stop offset="1" stopColor="#5C0E16"></stop>
                </linearGradient>
                <linearGradient
                    id="paint49_linear_0_53"
                    x1="310.161"
                    x2="335.575"
                    y1="291.378"
                    y2="280.674"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09005D"></stop>
                    <stop offset="1" stopColor="#1A0F91"></stop>
                </linearGradient>
                <linearGradient
                    id="paint50_linear_0_53"
                    x1="1167.7"
                    x2="1193.41"
                    y1="618.492"
                    y2="628.095"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4421B"></stop>
                    <stop offset="1" stopColor="#5C0E16"></stop>
                </linearGradient>
                <linearGradient
                    id="paint51_linear_0_53"
                    x1="322.434"
                    x2="378.077"
                    y1="164.55"
                    y2="68.015"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint52_linear_0_53"
                    x1="345.346"
                    x2="400.995"
                    y1="177.758"
                    y2="81.218"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEBBBA"></stop>
                    <stop offset="1" stopColor="#FF928E"></stop>
                </linearGradient>
                <linearGradient
                    id="paint53_linear_0_53"
                    x1="147.089"
                    x2="217.21"
                    y1="288.667"
                    y2="197.067"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <linearGradient
                    id="paint54_linear_0_53"
                    x1="237.078"
                    x2="205.675"
                    y1="282.46"
                    y2="303.401"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F52FF"></stop>
                    <stop offset="1" stopColor="#4042E2"></stop>
                </linearGradient>
                <clipPath id="clip0_0_53">
                    <path fill="#fff" d="M0 0H437V434H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MainImg;
