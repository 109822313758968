import React from "react";

function FinancialSecurity() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="none"
            viewBox="0 0 50 50"
        >
            <mask
                id="mask0_0_932"
                style={{ maskType: "alpha" }}
                width="50"
                height="50"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#4F96F6" d="M0 0H50V50H0z"></path>
            </mask>
            <g mask="url(#mask0_0_932)">
                <path
                    fill="#4F96F6"
                    d="M21.384 4.104c-3.62 0-6.665 2.676-6.665 6.189v8.093c-1.85 0-3.332 1.56-3.332 3.422v20.755c0 1.862 1.483 3.437 3.332 3.437h20.948C37.517 46 39 44.425 39 42.563V21.808c0-1.861-1.483-3.422-3.333-3.422v-8.093c0-3.513-3.045-6.19-6.665-6.19h-7.617zm0 1.904h7.618c2.71 0 4.761 1.863 4.761 4.285v8.093h-1.904v-5.713c0-2.594-1.84-4.76-4.285-4.76h-4.761c-2.445 0-4.285 2.166-4.285 4.76v5.713h-1.904v-8.093c0-2.422 2.05-4.285 4.76-4.285zm1.429 3.809h4.76c1.354 0 2.381 1.098 2.381 2.856v5.713h-9.522v-5.713c0-1.758 1.027-2.856 2.38-2.856zM14.719 20.29h20.948c.788 0 1.429.64 1.429 1.517v20.755c0 .878-.64 1.533-1.429 1.533H14.72c-.788 0-1.428-.655-1.428-1.533V21.808c0-.877.64-1.517 1.428-1.517zm10.474 1.904c-5.51 0-9.998 4.488-9.998 9.998 0 5.51 4.488 9.998 9.998 9.998 5.51 0 9.998-4.487 9.998-9.998 0-5.51-4.487-9.998-9.998-9.998zm0 1.905a8.08 8.08 0 018.094 8.093 8.08 8.08 0 01-8.094 8.094 8.08 8.08 0 01-8.093-8.094 8.08 8.08 0 018.093-8.093zm-.104 1.89a.952.952 0 00-.848.966v.52a2.877 2.877 0 00-2.38 2.813c0 1.623 1.52 2.856 3.332 2.856 1.062 0 1.429.465 1.429.953 0 .614-.24.952-.953.952h-2.856a.953.953 0 100 1.904h1.428v.476a.951.951 0 101.904 0v-.52a2.878 2.878 0 002.381-2.812c0-1.623-1.52-2.857-3.333-2.857-1.061 0-1.428-.465-1.428-.952 0-.615.24-.952.952-.952h2.857a.955.955 0 00.965-.953.951.951 0 00-.965-.952h-1.429v-.476a.954.954 0 00-1.056-.967z"
                ></path>
            </g>
        </svg>
    );
}

export default FinancialSecurity;
