import React, { ReactElement } from "react";
import MainImg from "./mainImg";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
export default function WhatIsHomeWarranty(): ReactElement {
    return (
        <section className={`${styles["mainContainer"]}`}>
            <div className="flex flex-col lg:flex-row lg:gap-28 gap-16  lg:pt-28 pt-14 lg:pb-32 pb-16 ">
                <div className="max-w-[574px]  mx-auto lg:mx-0 ">
                    <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] ">
                        What Is a Home Warranty For?
                    </h2>
                    <p className="mt-5   lg:text-lg text-base lg:leading-8  font-light">
                        A home warranty is a service contract that provides
                        coverage for repairing or replacing major home systems
                        and appliances. It is designed to protect homeowners
                        from unexpected expenses that arise due to the breakdown
                        of essential household items. With a home warranty,
                        homeowners can be at ease knowing that if a covered item
                        malfunctions or stops working, they can contact their
                        warranty provider to arrange for repairs or
                        replacements. This can save homeowners from incurring
                        high repair or replacement costs, providing financial
                        protection and added convenience for their homes.
                    </p>
                    <div className="lg:mt-[50px] mt-7 text-center sm:text-left">
                        <SharedButton />
                    </div>
                    <span className="w-[122px] h-[8px] lg:block bg-[#F02B17] mt-9 hidden"></span>
                </div>
                <div className="w-[300px] h-[250px] md:h-[434px] md:w-[437px] md:min-w-[437px] relative mx-auto lg:mx-0 lg:mt-10">
                    <MainImg />
                </div>
            </div>
        </section>
    );
}
