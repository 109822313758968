import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import S3Icon from "@components/shared/s3Icon";
import MainImg from "./mainImg";
import { useDomainContext } from "@hooks/useDomainContext";

export default function MainBanner(): ReactElement {
    const { domain, formattedTitle } = useDomainContext();

    const categories = domain?.categories ?? [];

    return (
        <section className={`${styles["mainSection"]}`}>
            <div className="lg:pt-40 pt-10 pb-16 ">
                <div className=" flex flex-col lg:flex-row gap-10     lg:items-center ">
                    <div className="max-w-[575px] mx-auto lg:mx-0 ">
                        <h1
                            className="font-bold lg:text-4xl text-2xl lg:leading-[45px]  "
                            dangerouslySetInnerHTML={{
                                __html:
                                    formattedTitle ??
                                    "We Help You Find the Best Warranty Companies",
                            }}
                        ></h1>
                        <p className="mt-5   lg:text-2xl  text-lg lg:leading-8 font-light text-[#444444]">
                            What kind of warranty are you looking for?
                        </p>

                        {categories.length ? (
                            <div className={` ${styles["categories-wrapper"]}`}>
                                {categories?.map((category, index) => {
                                    return (
                                        <a
                                            href={`/${
                                                category.slugAlias ??
                                                category.slug
                                            }`}
                                            className={styles["category-box"]}
                                            key={index}
                                        >
                                            <div className="relative">
                                                {category.icon && (
                                                    <S3Icon
                                                        image={category.icon}
                                                        svgClassName={
                                                            styles[
                                                                "category-icon"
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </div>

                                            <span>
                                                {category.label
                                                    ? category.label
                                                    : category.name}
                                            </span>
                                        </a>
                                    );
                                })}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="w-[300px] h-[200px] md:w-[560px] md:min-w-[560px] md:h-[374px]  relative  mx-auto lg:mx-0 ">
                        <MainImg />
                    </div>
                </div>
                <div className="lg:mt-56 mt-20">
                    <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px]">
                        Why Get Warranties
                    </h2>
                    <p className="mt-5   lg:text-lg  text-base lg:leading-8 font-light ">
                        Car and home warranties can provide peace of mind and
                        financial security by covering unexpected expenses for
                        mechanical failures and major home systems/appliances.
                        They offer convenience, protection, and potential
                        savings, reducing the financial burden and allowing
                        consumers to enjoy their homes and vehicles worry-free.
                    </p>
                </div>
            </div>
        </section>
    );
}
